<template>
  <div class="wrapper">
    <ZeRenMianChu v-if="fileName === 'zeRenMianChu'" />
    <TeBieYueDing v-else-if="fileName === 'teBieYueDing'" />
    <GeRenXinXiShouQuan v-else />
  </div>
</template>

<script>
import ZeRenMianChu from "./components/ZeRenMianChu";
import TeBieYueDing from "./components/Tbyd";
import GeRenXinXiShouQuan from "./components/GeRenXinXiShouQuan";

export default {
  components: {
    ZeRenMianChu,
    TeBieYueDing,
    GeRenXinXiShouQuan,
  },
  computed: {
    fileName() {
      return this.$route.params.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 16px;
}
</style>
